<template>
  <div class="hero-section">
    <div class="container">
      <div class="d-flex justify-content-center d-sm-none">
        <img src="@/assets/mainpic.jpg" style="max-width: 95vw" />
      </div>
      <div class="hero-content fit-content">
        <div class="d-flex justify-content-center">
          <h1>Dr. Ryan Graebert</h1>
        </div>

        <div class="text-md d-flex justify-content-center">
          Cellist, Performer, Teacher
        </div>

        <div class="mx-auto fit-content">
          <div class="d-flex justify-content-center">
            <router-link :to="{ name: 'BioVue' }" class="cta-button me-1"
              >Bio</router-link
            >

            <router-link :to="{ name: 'RecordingsVue' }" class="cta-button me-1"
              >Recordings</router-link
            >
            <router-link :to="{ name: 'StudioVue' }" class="cta-button me-1"
              >Studio</router-link
            >
          </div>
          <div class="text-md mt-3">Founder of Stand Partner</div>
          <div class="d-flex justify-content-center">
            <a href="https://www.standpartner.app" class="btn btn-light mt-3"
              ><img
                src="https://d4sh7wmdju2hf.cloudfront.net/static/dist/img/stand-partner-logo-horizontal-rgb.7584b512.png"
                style="width: 200px"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axios } from "@/common/api.service.js";
export default {
  name: "HomeView",
  data() {
    return {
      bio: {
        name: "",
        bio_text: "",
        image: "",
      },
    };
  },
  mounted() {
    axios
      .get("/pages/api/bio/1/")
      .then((response) => {
        this.bio = response.data;
      })
      .catch((error) => {
        console.error("Error fetching bio data:", error);
      });
  },
};
</script>
<style scoped>
.hero-section {
  height: 100vh;

  color: #ffffff;
  display: flex;
  align-items: center;
  position: relative; /* Add this line */
}

.hero-content {
  max-width: 800px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: 20px;
  background: transparent;
  border-radius: 10px;
}
.hero-content {
  margin: auto;
  padding: 20px;
  background: transparent;
  border-radius: 10px;
}
@media (min-width: 576px) {
  .hero-section {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
      url("@/assets/mainpic.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .hero-content {
    margin-right: 0px;
  }
}

p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.cta-button {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 16px;
  background-color: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #e64a19;
}
</style>
