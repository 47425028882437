<template>
  <div class="base-content">
    <router-view />
    <footer class="footer text-sm">
      ryan.graebert@gmail.com | 440.935.2110
    </footer>
  </div>
</template>

<style>
body {
  background-color: #000000 !important;
}
.footer {
  position: fixed;
  bottom: 0px;
  padding: 4px;
  color: #cccccc;
  width: 100%;
  text-align: center;
  margin: auto;
  background-color: #000000;
}
.base-content {
  width: 100%;
  height: 100%;
  color: #ffffff;
  overflow-y: auto;
  background-color: #000000;
}

.fit-content {
  width: fit-content !important;
  height: fit-content !important;
}
.text-xs {
  font-size: 0.8rem !important;
}
.text-sm {
  font-size: 1rem !important;
}
.text-md {
  font-size: 1.5rem !important;
}
.text-lg {
  font-size: 2rem !important;
}
.text-xl {
  font-size: 2.5rem !important;
}

.brand-image {
  max-width: 260px; /* Keeps the image within its container */
  height: auto; /* Preserves the image's aspect ratio */

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  float: left;
  margin: 1rem;
}
</style>
