import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeVue',
    component: HomeView
  },
  {
    path: '/bio',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
      {
        path: '/bio',
        name: 'BioVue',
        component: () => import(/* webpackChunkName: "biography" */ '../views/BioView.vue')
      },

    ],
  },
  {
    path: '/recordings',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
      {
        path: '',
        name: 'RecordingsVue',
        component: () => import(/* webpackChunkName: "biography" */ '../views/RecordingsView.vue')
      },

    ],
  },
  {
    path: '/studio',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
      {
        path: '',
        name: 'StudioVue',
        component: () => import(/* webpackChunkName: "biography" */ '../views/StudioView.vue')
      },

    ],
  },

]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

export default router
